
<template>
	<div>
		<s-crud
			:filter="filter"
			:config="config"
			title="Kardex Maduración"
		>
			<template v-slot:filter>
				<v-container>
					<v-row justify="center">
						<v-col lg="3">
							<s-select-definition
								label="Semana"
								:def="1310"
								v-model="filter.Week"
							></s-select-definition>
						</v-col>
						<v-col lg="3">
							<s-select-definition
								label="Cultivo"
								:def="1173"
								v-model="filter.TypeCultive"
							></s-select-definition>
						</v-col>
						<v-col lg="3">
							<s-select-variety
								clearable
								:cultiveID="filter.TypeCultive"
								label="Variedad"
								full
								v-model="filter.VrtID"
							/>
							<!-- <s-select-variety
								label="Variedad"
								:cultiveID="filter2.TypeCultive"
								v-model="filter.VrtID"
							></s-select-variety> -->
						</v-col>
						<v-col lg="3">
							<s-select-definition
								clearable
								label="Tamaño"
								:def="1326"
								v-model="filter.TypeFrozenCategory"
							></s-select-definition>
						</v-col>
					</v-row>
				</v-container>
			</template>
		</s-crud>
	</div>
</template>

<script>
	import _sStockMaduration from '@/services/FrozenProduction/StockMadurationService';
	import SSelectVariety from '../../../components/HarvestSchedule/SSelectVariety.vue';

	export default {
  		components: { SSelectVariety },
		data() {
			return {
				config: {
					service: _sStockMaduration,
					model: {
						MtpID: "ID",
					},
					headers: [
						{ text: "ID", value: "MtpID" },
						{ text: "Lote MP", value: "PrfYuliano" },
						{ text: "# Jabas", value: "LlpQuantityJaba"},
						{ text: "Tamaño", value: "TypeFrozenCategoryName"},
						{ text: "Tamaño Abb", value: "TypeFrozenAbbreviation"},
						{ text: "Cultivo", value: "TypeCultiveName"},
						{ text: "Variedad", value: "VrtName"},
						{ text: "Fondo/Flota", value: "TypeSelectionFrozenName"},
						{ text: "MS", value: "MS"},
						{ text: "Peso Lavado", value: "WeightWashed"},
					],
				},
				filter:{},
				filter2: {}
			}
		},
	}
</script>

<style lang="scss" scoped>

</style>